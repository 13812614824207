import axios from "axios";
import { BASE_URL } from "../constants/DataConstant";

const ax = axios.create({ baseURL: BASE_URL });

export const adminLogin = async (data) => {
  let config = {
    method: "post",
    url: `orginfo/adminLogin`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const uploadData = async (data) => {
  let config = {
    method: "post",
    url: `student/uploadData`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getAllStudents = async () => {
  let config = {
    method: "get",
    url: `student/getAllStudents`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getStudents = async (data) => {
  let config = {
    method: "post",
    url: `student/getStudents`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getSingleStudent = async (id) => {
  let config = {
    method: "get",
    url: `student/getSingleStudent/${id}`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const updateDownloadStatus = async (id) => {
  let config = {
    method: "get",
    url: `student/updateDownloadStatus/${id}`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const updateStudent = async (data) => {
  let config = {
    method: "put",
    url: `student/updateStudent`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const deleteStudent = async (id) => {
  let config = {
    method: "delete",
    url: `student/deleteStudent/${id}`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getAllLabel = async () => {
  let config = {
    method: "get",
    url: `label/getAllLabel`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const updateLabel = async (data) => {
  let config = {
    method: "put",
    url: `label/updateLabel`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getAllClass = async () => {
  let config = {
    method: "get",
    url: `class/getAllClass`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const createClass = async (data) => {
  let config = {
    method: "post",
    url: `class/createClass`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const updateClass = async (data) => {
  let config = {
    method: "put",
    url: `class/updateClass`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const deleteClass = async (id) => {
  let config = {
    method: "delete",
    url: `class/deleteClass/${id}`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getAllYear = async () => {
  let config = {
    method: "get",
    url: `year/getAllYear`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};
export const createYear = async (data) => {
  let config = {
    method: "post",
    url: `year/createYear`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const updateYear = async (data) => {
  let config = {
    method: "put",
    url: `year/updateYear`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const deleteYear = async (id) => {
  let config = {
    method: "delete",
    url: `year/deleteYear/${id}`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const updateMultipleStudent = async (data) => {
  let config = {
    method: "post",
    url: `student/updateMultipleStudent`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const deleteMultipleStudent = async (data) => {
  let config = {
    method: "post",
    url: `student/deleteMultipleStudent`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const uploadFont = async (data) => {
  let config = {
    method: "post",
    url: `font/updateFont`,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const getFonts = async () => {
  let config = {
    method: "get",
    url: `font/getFonts`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};

export const setDefaultFont = async () => {
  let config = {
    method: "get",
    url: `font/setDefaultFont`,
    headers: { "Content-Type": "application/json" },
  };
  const response = await ax.request(config);
  return response?.data;
};
