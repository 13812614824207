import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../services/Loader";
import {
  uploadData,
  getAllClass,
  getAllYear,
  createClass,
  createYear,
  updateClass,
  updateYear,
  deleteClass,
  deleteYear,
  uploadFont,
  setDefaultFont,
} from "../../services/APIService";
import * as XLSX from "xlsx";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ExcelData } from "../../redux/actions";

export default function UploadData() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eData = useSelector((state) => state.ExcelData);

  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [msgLocation, setMsgLocation] = useState(0);
  const [studentData, setStudentData] = useState([]);
  const [studClass, setClass] = useState();
  const [studYear, setYear] = useState();
  const [classData, setClassData] = useState();
  const [yearData, setYearData] = useState();

  const [classStatus, setClassStatus] = useState(0);
  const [classBtnText, setClassBtnText] = useState("Create");
  const [yearStatus, setYearStatus] = useState(0);
  const [yearBtnText, setYearBtnText] = useState("Create");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const {
    register: registerClass,
    handleSubmit: handleSubmitClass,
    formState: { errors: errorsClass },
    reset: resetClass,
  } = useForm();

  const {
    register: registerYear,
    handleSubmit: handleSubmitYear,
    formState: { errors: errorsYear },
    reset: resetYear,
  } = useForm();

  const {
    register: registerFont,
    handleSubmit: handleSubmitFont,
    formState: { errors: errorsFont },
    reset: resetFont,
  } = useForm();

  useEffect(() => {
    loadClass();
    loadYear();
  }, []);

  const loadClass = async () => {
    try {
      setIsLoading(true);
      var response = await getAllClass();
      setClassData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const loadYear = async () => {
    try {
      setIsLoading(true);
      var response = await getAllYear();
      setYearData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setStudentData(jsonData);
      dispatch(ExcelData(jsonData));
    };

    reader.readAsBinaryString(file);
  };

  const downloadFile = () => {
    // Create a link element
    const link = document.createElement("a");
    // Set the href attribute to the file URL
    link.href = `${process.env.PUBLIC_URL}/assets/data/SampleData.xlsx`;
    // Set the download attribute to suggest a filename
    link.setAttribute("download", "SampleData.xlsx");
    // Append the link to the body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };

  const onSubmit = async (item) => {
    try {
      setIsLoading(true);
      for (let i = 0; i < eData.length; i++) {
        eData[i].Class_Id = item.Class_Id;
        eData[i].Year_Id = item.Year_Id;
      }
      const response = await uploadData(eData);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(1);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(1);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error.message);
      setMsgLocation(1);
    }
  };

  const onSubmitClass = async (data) => {
    try {
      setIsLoading(true);
      var response;
      if (classStatus == 0) {
        data.id = undefined;
        response = await createClass(data);
      } else {
        response = await updateClass(data);
      }
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(2);
        resetClass();
        setClassStatus(0);
        setClassBtnText("Create");
        resetClass({ className: "" });
        loadClass();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(2);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error.message);
      setMsgLocation(2);
    }
  };

  const onSubmitYear = async (data) => {
    try {
      setIsLoading(true);
      var response;
      if (yearStatus == 0) {
        data.id = undefined;
        response = await createYear(data);
      } else {
        response = await updateYear(data);
      }
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(3);
        resetYear();
        setYearStatus(0);
        setYearBtnText("Create");
        resetYear({ year: "" });
        loadYear();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(3);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error.message);
      setMsgLocation(3);
    }
  };

  const onClassEdit = (item) => {
    resetClass(item);
    setClassStatus(1);
    setClassBtnText("Update");
  };
  const cancelClassUpdate = () => {
    resetClass();
    setClassStatus(0);
    setClassBtnText("Create");
  };

  const onYearEdit = (item) => {
    resetYear(item);
    setYearStatus(1);
    setYearBtnText("Update");
  };

  const cancelYearUpdate = () => {
    resetYear();
    setYearStatus(0);
    setYearBtnText("Create");
  };

  const onClassDelete = async (item) => {
    if (window.confirm("Confirm delete..?")) {
      try {
        setIsLoading(true);
        var response = await deleteClass(item.id);
        if (response.status) {
          setShowMsg(true);
          setMsgClass("alert alert-success alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(2);
          loadClass();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(2);
        }
        setIsLoading(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };
  const onYearDelete = async (item) => {
    if (window.confirm("Confirm delete..?")) {
      try {
        setIsLoading(true);
        var response = await deleteYear(item.id);
        if (response.status) {
          setShowMsg(true);
          setMsgClass("alert alert-success alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(3);
          loadYear();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(3);
        }
        setIsLoading(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  const onFontSubmit = async (data) => {
    try {
      setIsLoading(true);
      const fd = new FormData();
      fd.append("fontFamily", data.fontFamily);
      fd.append("normal", data.normal[0]);
      fd.append("bold", data.bold[0]);
      var response = await uploadFont(fd);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(4);
        resetFont();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(4);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Only (.ttf) font files are allowed..!");
      setMsgLocation(4);
    }
  };
  const resetDefaultFont = async () => {
    try {
      if (window.confirm("Do you want to confirm set default font.?")) {
        setIsLoading(true);
        var response = await setDefaultFont();
        if (response.status) {
          setShowMsg(true);
          setMsgClass("alert alert-success alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(4);
          resetFont();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(4);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error.message);
      setMsgLocation(4);
    }
  };

  return (
    <div className="container-fluid  dashboard-content">
      {isLoading && <Loader />}

      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="card">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h5 className="card-header">Upload Student Data</h5>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                    <a
                      className="text-primary"
                      href="javascript:void(0);"
                      onClick={downloadFile}
                    >
                      👉 Download Sample Excel File Here
                    </a>
                  </div>
                  {msgLocation == 1 && (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      {showMsg && (
                        <div
                          className={msgClass}
                          //className="alert alert-success alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>{msgText}</strong>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Select Class</label>
                    <div className="input-group">
                      <select
                        type="text"
                        className="form-control"
                        {...register("Class_Id", { required: "Select class" })}
                      >
                        <option value="">Select Class</option>
                        {classData?.length > 0 &&
                          classData.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.className}
                            </option>
                          ))}
                      </select>{" "}
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#classModal"
                        >
                          Add
                        </button>
                      </div>
                    </div>

                    {errors.Class_Id && (
                      <p className="text-danger">{errors.Class_Id.message}</p>
                    )}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Select Year</label>
                    <div className="input-group">
                      <select
                        type="text"
                        className="form-control"
                        {...register("Year_Id", { required: "Select year" })}
                      >
                        <option value="">Select Year</option>
                        {yearData &&
                          yearData.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.year}
                            </option>
                          ))}
                      </select>
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#yearModal"
                        >
                          Add
                        </button>
                      </div>
                    </div>

                    {errors.Year_Id && (
                      <p className="text-danger">{errors.Year_Id.message}</p>
                    )}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Upload Excel</label>
                    <input
                      type="file"
                      required=""
                      placeholder="Type something"
                      className="form-control"
                      accept=".xlsx, .xls"
                      // onChange={handleFileUpload}
                      {...register("excelFile", {
                        required: "Select excel file",
                        onChange: handleFileUpload,
                      })}
                    />
                    {errors.excelFile && (
                      <p className="text-danger">{errors.excelFile.message}</p>
                    )}
                  </div>
                </div>
                <div className="form-row mt-5">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button className="btn btn-primary mr-3" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div
            className="modal fade"
            id="classModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Manage Class
                  </h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </a>
                </div>
                <div className="modal-body">
                  <form action="" onSubmit={handleSubmitClass(onSubmitClass)}>
                    {msgLocation == 2 && (
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        {showMsg && (
                          <div
                            className={msgClass}
                            //className="alert alert-success alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>{msgText}</strong>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                      {/* <label for="validationCustom01">Class Name</label> */}
                      <div className="input-group">
                        <input
                          hidden
                          type="text"
                          className="form-control"
                          placeholder="Enter class name"
                          {...registerClass("id")}
                        />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter class name"
                          {...registerClass("className", {
                            required: "Enter class name",
                          })}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary">
                            {classBtnText}
                          </button>
                        </div>
                        {classStatus == 1 && (
                          <div className="input-group-append">
                            <button
                              type="button"
                              onClick={cancelClassUpdate}
                              className="btn btn-danger"
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>

                      {errorsClass.className && (
                        <p className="text-danger">
                          {errorsClass.className.message}
                        </p>
                      )}
                    </div>
                  </form>
                  <div className="card">
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Sr. No</th>
                            <th scope="col">Class Name</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {classData &&
                            classData.map((item, i) => (
                              <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{item.className}</td>
                                <td>
                                  <i
                                    onClick={() => onClassEdit(item)}
                                    className="fas fa-edit mr-3 text-primary fa-lg"
                                  ></i>
                                  <i
                                    onClick={() => onClassDelete(item)}
                                    className="fas fa-trash-alt text-danger fa-lg"
                                  ></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <a
                    href="#"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="yearModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Manage Year
                  </h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </a>
                </div>
                <div className="modal-body">
                  <form action="" onSubmit={handleSubmitYear(onSubmitYear)}>
                    {msgLocation == 3 && (
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        {showMsg && (
                          <div
                            className={msgClass}
                            //className="alert alert-success alert-dismissible fade show"
                            role="alert"
                          >
                            <strong>{msgText}</strong>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                      {/* <label for="validationCustom01">Class Name</label> */}
                      <div className="input-group">
                        <input
                          hidden
                          type="text"
                          className="form-control"
                          placeholder="Enter class name"
                          {...registerYear("id")}
                        />
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter year"
                          {...registerYear("year", {
                            required: "Enter year",
                          })}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary">
                            {yearBtnText}
                          </button>
                        </div>
                        {yearStatus == 1 && (
                          <div className="input-group-append">
                            <button
                              type="button"
                              onClick={cancelYearUpdate}
                              className="btn btn-danger"
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>

                      {errorsYear.className && (
                        <p className="text-danger">{errorsYear.year.message}</p>
                      )}
                    </div>
                  </form>
                  <div className="card">
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Sr. No</th>
                            <th scope="col">Year</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {yearData &&
                            yearData.map((item, i) => (
                              <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{item.year}</td>
                                <td>
                                  <i
                                    onClick={() => onYearEdit(item)}
                                    className="fas fa-edit mr-3 text-primary fa-lg"
                                  ></i>
                                  <i
                                    onClick={() => onYearDelete(item)}
                                    className="fas fa-trash-alt text-danger fa-lg"
                                  ></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <a
                    href="#"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="card">
            <form
              className="needs-validation"
              onSubmit={handleSubmitFont(onFontSubmit)}
            >
              <h5 className="card-header">Font Settings</h5>
              <div className="card-body">
                <div className="row">
                  {msgLocation == 4 && (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      {showMsg && (
                        <div
                          className={msgClass}
                          //className="alert alert-success alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>{msgText}</strong>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Font Family Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter font family name"
                        {...registerFont("fontFamily", {
                          required: "Enter font family name",
                        })}
                      />
                    </div>

                    {errorsFont.fontFamily && (
                      <p className="text-danger">
                        {errorsFont.fontFamily.message}
                      </p>
                    )}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Upload Regular Font</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        {...registerFont("normal", {
                          required: "Select normal font (.ttf) file",
                        })}
                      />
                    </div>

                    {errorsFont.normal && (
                      <p className="text-danger">{errorsFont.normal.message}</p>
                    )}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Upload Bold Font</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        {...registerFont("bold", {
                          required: "Select bold font  (.ttf) file",
                        })}
                      />
                    </div>

                    {errorsFont.bold && (
                      <p className="text-danger">{errorsFont.bold.message}</p>
                    )}
                  </div>
                </div>
                <div className="form-row mt-5">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button className="btn btn-primary mr-3" type="submit">
                      Upload
                    </button>
                    <button
                      onClick={resetDefaultFont}
                      className="btn btn-info mr-3"
                      type="button"
                    >
                      Reset Default Font
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
