import React, { useState, useEffect } from "react";
import {
  updateDownloadStatus,
  getStudents,
  deleteStudent,
  updateMultipleStudent,
  deleteMultipleStudent,
} from "../../services/APIService";
import { useNavigate } from "react-router-dom";
import Loader from "../../services/Loader";
import { useDispatch } from "react-redux";
import { LCData, UpdateData, FontData } from "../../redux/actions";
import DataTable from "react-data-table-component";
import { getAllClass, getAllYear, getFonts } from "../../services/APIService";
import { useForm } from "react-hook-form";

export default function DownloadLC() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [msgLocation, setMsgLocation] = useState(0);
  const [studentData, setStudentData] = useState();
  const [search, setSearch] = useState("");
  const [classData, setClassData] = useState();
  const [yearData, setYearData] = useState();
  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedYearId, setSelectedYearId] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [btnStatus, setBtnStatus] = useState(false);
  const [bulkUpdateData, setBulkUpdateData] = useState();
  const [bulkDeleteData, setBulkDeleteData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    loadClass();
    loadYear();
    loadStudents();
  }, [selectedClassId, selectedYearId]);

  const loadClass = async () => {
    try {
      setIsLoading(true);
      var response = await getAllClass();
      setClassData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const loadYear = async () => {
    try {
      setIsLoading(true);
      var response = await getAllYear();
      setYearData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const loadStudents = async () => {
    try {
      setIsLoading(true);
      var data = {
        classId: selectedClassId,
        yearId: selectedYearId,
      };
      if (selectedClassId && selectedYearId) {
        var response = await getStudents(data);
        for (let i = 0; i < response.length; i++) {
          response[i].fullName =
            response[i].firstName +
            " " +
            response[i].middleName +
            " " +
            response[i].lastName;
        }
        setStudentData(response);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const viewLC = async (item) => {
    if (!item.isDownloaded) {
      updateStatus(item.studId);
    }
    localStorage.setItem("LCData", JSON.stringify(item));
    //navigate("/dashboard/ViewLC", { state: item }); // pass data to component using route
    loadFonts(item);
  };

  const loadFonts = async (item) => {
    try {
      setIsLoading(true);
      var response = await getFonts();
      dispatch(LCData(item));
      navigate("/dashboard/ViewLC", { state: response[0] });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const editStudent = (item) => {
    if (window.confirm("Edit this selected record..?")) {
      localStorage.setItem("UpdateData", JSON.stringify(item));
      dispatch(UpdateData(item));
      navigate("/dashboard/edit-student");
    }
  };

  const deleteRecord = async (item) => {
    if (window.confirm("Confirm delete..?")) {
      try {
        setIsLoading(true);
        var response = await deleteStudent(item.studId);
        if (response.status) {
          setShowMsg(true);
          setMsgClass("alert alert-success alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(1);
          loadStudents();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(1);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  const updateStatus = async (id) => {
    try {
      setIsLoading(true);
      var response = await updateDownloadStatus(id);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(1);
        localStorage.setItem("AdminLogin", JSON.stringify(response.data));
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(1);
      }
      setIsLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      name: <b>Student ID</b>,
      selector: (row) => row.studId,
      sortable: true,
      width: "130px",
    },
    {
      name: <b>Student Name</b>,
      selector: (row) => row.fullName,
      sortable: true,
      width: "200px",
    },
    {
      name: <b>Mother Name</b>,
      selector: (row) => row.motherName,
      sortable: true,
      width: "130px",
    },
    {
      name: <b>Adhar No</b>,
      selector: (row) => row.adharNo,
      sortable: true,
      width: "130px",
    },
    {
      name: <b>Taluka</b>,
      selector: (row) => row.taluka,
      sortable: true,
      width: "120px",
    },
    {
      name: <b>Admission Date</b>,
      selector: (row) => row.admissionDate,
      sortable: true,
      width: "150px",
    },
    {
      name: <b>Download Status</b>,
      selector: (row) =>
        row.isDownloaded ? (
          <span className="badge badge-success">Downloaded</span>
        ) : (
          <span className="badge badge-danger">Pending</span>
        ),
      sortable: true,
      width: "150px",
    },
    {
      name: <b>Actions</b>,
      cell: (row) => (
        <div>
          <i
            onClick={() => viewLC(row)}
            className="fas fa-download mr-3 text-success fa-lg"
          ></i>
          <i
            onClick={() => editStudent(row)}
            className="fas fa-edit mr-3 text-primary fa-lg"
          ></i>
          <i
            onClick={() => deleteRecord(row)}
            className="fas fa-trash-alt text-danger fa-lg"
          ></i>
        </div>
      ),
      width: "150px",
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = studentData?.filter(
    (item) =>
      item.fullName.toLowerCase().includes(search.toLowerCase()) ||
      item.studId.toLowerCase().includes(search.toLowerCase()) ||
      item.adharNo.toLowerCase().includes(search.toLowerCase())
  );

  const handleClassChange = (e) => {
    setSelectedClassId(e.target.value);
    // loadStudents();
  };

  const handleYearChange = (e) => {
    setSelectedYearId(e.target.value);
    // loadStudents();
  };

  const handleRowSelected = (state) => {
    //setSelectedRows(state.selectedRows.map((row) => row.id));
    setBulkUpdateData(state.selectedRows);
    setBulkDeleteData(state.selectedRows.map((row) => row.id));

    if (state.selectedRows.length > 0) {
      setBtnStatus(true);
      reset({
        classId: state.selectedRows[0]?.classId,
        yearId: state.selectedRows[0]?.yearId,
      });
    } else {
      setBtnStatus(false);
    }
  };

  const bulkDelete = async () => {
    if (window.confirm("Do you want to delete all selected records.?")) {
      try {
        setIsLoading(true);
        var response = await deleteMultipleStudent(bulkDeleteData);
        if (response.status) {
          setShowMsg(true);
          setMsgClass("alert alert-success alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(1);
          loadStudents();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger alert-dismissible fade show");
          setMsgText(response.message);
          setMsgLocation(1);
        }
        setIsLoading(false);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      for (let i = 0; i < bulkUpdateData.length; i++) {
        bulkUpdateData[i].classId = data.classId;
        bulkUpdateData[i].yearId = data.yearId;
      }
      const response = await updateMultipleStudent(bulkUpdateData);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(2);
        loadStudents();
        reset();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
        setMsgLocation(2);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error.message);
      setMsgLocation(2);
    }
  };

  return (
    <div className="dashboard-influence">
      {isLoading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h3 className="mb-2">Student List </h3>

              <div className="page-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb"></ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                {showMsg && (
                  <div className={msgClass} role="alert">
                    <strong>{msgText}</strong>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                )}

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Select Class</label>
                    <select
                      type="text"
                      className="form-control"
                      onChange={handleClassChange}
                    >
                      <option value="">Select Class</option>
                      <option value="All">All</option>
                      {classData?.length > 0 &&
                        classData.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.className}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Select Year</label>
                    <select
                      type="text"
                      className="form-control"
                      onChange={handleYearChange}
                    >
                      <option value="">Select Year</option>
                      <option value="All">All</option>
                      {yearData &&
                        yearData.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.year}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                {studentData?.length > 0 ? (
                  <div className="table-responsive">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                        <hr />
                        {/* <label for="validationCustom01">Search</label> */}
                        <div className="input-group">
                          <span class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-search"></i>
                            </span>
                          </span>
                          <input
                            placeholder="Search by name, student id, aadhar no"
                            className="form-control"
                            value={search}
                            onChange={handleSearch}
                          />
                        </div>

                        <hr />
                      </div>
                      {btnStatus && (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                          <hr />
                          {selectedClassId !== "All" &&
                            selectedYearId !== "All" && (
                              <button
                                className="btn btn-primary mr-3"
                                type="button"
                                data-toggle="modal"
                                data-target="#changeModal"
                              >
                                Update Class & Year
                              </button>
                            )}

                          <button
                            onClick={bulkDelete}
                            className="btn btn-danger"
                            type="button"
                          >
                            Delete All
                          </button>
                          <hr />
                        </div>
                      )}
                    </div>

                    <DataTable
                      className="table "
                      // title="Student List"
                      columns={columns}
                      data={filteredData}
                      pagination
                      highlightOnHover
                      selectableRows
                      onSelectedRowsChange={handleRowSelected}
                    />

                    {btnStatus && (
                      <div className="row mb-5">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                          <hr />
                        </div>
                      </div>
                    )}
                    {/* <table className="table table-striped table-bordered first">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Student ID</th>
                          <th>Student Name</th>
                          <th>Adhar No</th>
                          <th>Mother Name</th>
                          <th>Taluka</th>
                          <th>Admission Date</th>
                          <th>Download Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentData &&
                          studentData.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.studId}</td>
                              <td>
                                {item.firstName} {item.middleName}{" "}
                                {item.lastName}
                              </td>
                              <td>{item.adharNo}</td>
                              <td>{item.motherName}</td>
                              <td>{item.taluka}</td>
                              <td>{item.admissionDate}</td>
                              <td>
                                {item.isDownloaded ? (
                                  <span className="badge badge-success">
                                    Downloaded
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    Not Downloaded
                                  </span>
                                )}{" "}
                              </td>
                              <td>
                                {" "}
                                <button
                                  onClick={() => viewLC(item)}
                                  className="btn btn-rounded btn-primary btn-xs mr-2"
                                >
                                  Download
                                </button>
                                <button
                                  onClick={() => editStudent(item)}
                                  className="btn btn-rounded btn-brand btn-xs mr-2"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => deleteRecord(item)}
                                  className="btn btn-rounded btn-danger btn-xs"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      
                    </table> */}
                  </div>
                ) : (
                  <h3 className="card-header text-center mt-3">
                    Data not available..!
                  </h3>
                )}
              </div>
            </div>

            {/* {studentData?.length > 0 ? (
              <div className="card">

                <div className="card-body">
                  {showMsg && (
                    <div
                      className={msgClass}
                      role="alert"
                    >
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  )}
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered first">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Student ID</th>
                          <th>Student Name</th>
                          <th>Adhar No</th>
                          <th>Mother Name</th>
                          <th>Taluka</th>
                          <th>Admission Date</th>
                          <th>Download Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentData &&
                          studentData.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{item.studId}</td>
                              <td>
                                {item.firstName} {item.middleName}{" "}
                                {item.lastName}
                              </td>
                              <td>{item.adharNo}</td>
                              <td>{item.motherName}</td>
                              <td>{item.taluka}</td>
                              <td>{item.admissionDate}</td>
                              <td>
                                {item.isDownloaded ? (
                                  <span className="badge badge-success">
                                    Downloaded
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    Not Downloaded
                                  </span>
                                )}{" "}
                              </td>
                              <td>
                                {" "}
                                <button
                                  onClick={() => viewLC(item)}
                                  className="btn btn-rounded btn-primary btn-xs mr-2"
                                >
                                  Download
                                </button>
                                <button
                                  onClick={() => editStudent(item)}
                                  className="btn btn-rounded btn-brand btn-xs mr-2"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => deleteRecord(item)}
                                  className="btn btn-rounded btn-danger btn-xs"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="card-header text-center">Data not available..!</h3>
            )} */}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="changeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Class & Year
              </h5>
              <a
                href="#"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </a>
            </div>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                {msgLocation === 2 && (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {showMsg && (
                      <div
                        className={msgClass}
                        //className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>{msgText}</strong>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                  {/* <label for="validationCustom01">Class Name</label> */}
                  <div className="input-group">
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Enter year"
                      {...register("classId", {
                        required: "Select class",
                      })}
                    >
                      <option value="">Select Class</option>
                      {classData?.length > 0 &&
                        classData.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.className}
                          </option>
                        ))}
                    </select>
                  </div>

                  {errors.classId && (
                    <p className="text-danger">{errors.classId.message}</p>
                  )}
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                  {/* <label for="validationCustom01">Class Name</label> */}
                  <div className="input-group">
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Enter year"
                      {...register("yearId", {
                        required: "Select class",
                      })}
                    >
                      <option value="">Select Year</option>
                      {yearData &&
                        yearData.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.year}
                          </option>
                        ))}
                    </select>
                  </div>

                  {errors.yearId && (
                    <p className="text-danger">{errors.yearId.message}</p>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <a href="#" className="btn btn-secondary" data-dismiss="modal">
                  Close
                </a>
                <button className="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
