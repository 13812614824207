import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Loader from "../../services/Loader";
import { adminLogin } from "../../services/APIService";

import { useSelector, useDispatch } from "react-redux";
import { loginData } from "../../redux/actions";

export default function Login() {
  const counter = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [passToggle, setPassToggle] = useState("password");
  const [passToggleClass, setPassToggleClass] = useState("fas fa-eye");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await adminLogin(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        localStorage.setItem("AdminLogin", JSON.stringify(response.data));
        dispatch(loginData(response.data));
        navigate("/dashboard");
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  const passwordToggle = () => {
    if (passToggleClass == "fas fa-eye") {
      setPassToggleClass("fas fa-eye-slash");
      setPassToggle("text");
    } else {
      setPassToggleClass("fas fa-eye");
      setPassToggle("password");
    }
  };

  return (
    <div className="splash-container py-5">
      {isLoading && <Loader />}
      <div className="card ">
        <div className="card-header text-center">
          <a href="javascript:void(0);">
            <img
              className="logo-img pb-3"
              src="../assets/images/logo.png"
              alt="logo"
            />
          </a>
          <h2>LC App Login</h2>
          <span className="splash-description">
            Please enter login credentials.
          </span>
        </div>
        <div className="card-body">
          {showMsg && (
            <div
              className={msgClass}
              //className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <strong>{msgText}</strong>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                placeholder="Contact"
                {...register("contact", {
                  required: "Enter contact no",
                })}
              />
              {errors.contact && (
                <p className="text-danger">{errors.contact.message}</p>
              )}
            </div>
            <div className="form-group input-group">
              <input
                className="form-control form-control-lg"
                type={passToggle}
                placeholder="Password"
                {...register("password", {
                  required: "Enter password",
                })}
              />
              <div className="input-group-append" onClick={passwordToggle}>
                <button type="button" className="btn">
                  <i className={passToggleClass}></i>
                </button>
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password.message}</p>
              )}
            </div>
            <div className="form-group">
              <label className="custom-control custom-checkbox">
                {/* <input className="custom-control-input" type="checkbox" />
                <span className="custom-control-label">Remember Me</span> */}
              </label>
            </div>

            <button type="submit" className="btn btn-primary btn-lg btn-block">
              Login
            </button>
          </form>
        </div>
        {/* <div className="card-footer bg-white p-0  ">
          <div className="card-footer-item card-footer-item-bordered">
            <a href="#" className="footer-link">
              Create An Account
            </a>
          </div>
          <div className="card-footer-item card-footer-item-bordered">
            <a href="#" className="footer-link">
              Forgot Password
            </a>
          </div>
        </div> */}
        {/* <div>
          <h1>Counter: {counter}</h1>
          <button onClick={() => dispatch(increment())}>Increment</button>
          <button onClick={() => dispatch(decrement())}>Decrement</button>
        </div> */}
      </div>
    </div>
  );
}
