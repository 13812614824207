import React, { useState, useEffect } from "react";
import Loader from "../../services/Loader";
import { updateStudent } from "../../services/APIService";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAllClass, getAllYear } from "../../services/APIService";

export default function EditRecord() {
  const UpdateData = useSelector((state) => state.UpdateData);
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [classData, setClassData] = useState();
  const [yearData, setYearData] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    loadClass();
    loadYear();
  }, []);

  const loadClass = async () => {
    try {
      setIsLoading(true);
      var response = await getAllClass();
      setClassData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const loadYear = async () => {
    try {
      setIsLoading(true);
      var response = await getAllYear();
      setYearData(response);
      setIsLoading(false);
      reset(UpdateData);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await updateStudent(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        localStorage.setItem("UpdateData", JSON.stringify(data));
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error.message);
    }
  };

  return (
    <div className="container-fluid  dashboard-content">
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h5 className="card-header">Update Student Data</h5>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {showMsg && (
                      <div
                        className={msgClass}
                        //className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>{msgText}</strong>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Student Id</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      {...register("studId")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Select Class</label>
                    <select
                      type="text"
                      className="form-control"
                      {...register("classId")}
                    >
                      <option value="">Select Class</option>
                      {classData?.length > 0 &&
                        classData.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.className}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Select Year</label>
                    <select
                      type="text"
                      className="form-control"
                      {...register("yearId")}
                    >
                      <option value="">Select Year</option>
                      {yearData &&
                        yearData.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.year}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Reg No</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("regNo")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Aadhar No</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("adharNo")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("firstName")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Middle Name</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("middleName")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("lastName")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Mother Name</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("motherName")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Nationality</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("nationality")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Mother Tongue</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("motherTongue")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Religion</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("religion")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Caste</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("caste")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Sub Caste</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("subCaste")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Place of Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("placeOfBirth")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Taluka</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("taluka")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">District</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("district")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">State</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("state")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Date of Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("dob")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">DOB In Letter</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("dobInLetter")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Last School</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("lastSchool")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Admission Date</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("admissionDate")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Standard</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("std")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Progress</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("progress")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Conduct</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("conduct")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Date of Leaving</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("dateOfLeaving")}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      Std. In Which Studying
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("stdInWhichStudying")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Since When</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("sinceWhen")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      Studying Since When In Words
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("studyingSinceWhenInWords")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Leaving Reason</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("leavingReason")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Remark</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("remark")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("description")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      Change Download Status
                    </label>
                    <select
                      className="form-control"
                      {...register("isDownloaded")}
                    >
                      <option value="true">Downloaded</option>
                      <option value="false">Not Downloaded</option>
                    </select>
                  </div>
                </div>
                <div className="form-row mt-5">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button className="btn btn-primary mr-3" type="submit">
                      Save Changes
                    </button>
                    <Link
                      to={"/dashboard"}
                      className="btn btn-dark"
                      type="submit"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
