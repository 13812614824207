import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../services/Loader";
import { getAllLabel, updateLabel } from "../../services/APIService";
import { useForm } from "react-hook-form";

export default function ManageLabel() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [labelData, setlabelData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    loadLabels();
  }, []);

  const loadLabels = async () => {
    try {
      setIsLoading(true);
      var response = await getAllLabel();
      setlabelData(response);
      reset(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await updateLabel(data);
      if (response.status) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response.message);
        loadLabels();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error.message);
    }
  };

  return (
    <div className="container-fluid  dashboard-content">
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h5 className="card-header">Header Labels & Data</h5>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {showMsg && (
                      <div
                        className={msgClass}
                        //className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>{msgText}</strong>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Header Title 1</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("headerTitle1")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Header Title 2</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("headerTitle2")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Header Title 3</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("headerTitle3")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Reg. No Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("regNoLabel")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Reg. No</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("regNo")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Tag</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("tag")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("address")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Taluka Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("talukaLabel")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Taluka</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("taluka")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">District Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("districtLabel")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">District</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("district")}
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Phone Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("phoneLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("phone")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Email Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("emailLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("email")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      General Reg. No Label
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("genRegNoLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">General Reg No</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("genRegNo")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">U Dise No Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("uDiseNoLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">U Dise No</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("uDiseNo")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Board Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("boardLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Board</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("board")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">School Index Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("schoolIndexLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">School Index</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("schoolIndex")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Original Title</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("docOriginalTitle")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Duplicate Title</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("docDuplicateTitle")}
                    />
                  </div>
                </div>
              </div>
              <h5 className="card-header">Footer Labels & Data</h5>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Footer Message</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("footerMsg")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Date Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("dateLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Date</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("date")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Designation 1</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("designation1")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Designation 2</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("designation2")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Designation 3</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("designation3")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Note Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("noteLabel")}
                    />
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Note</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("note")}
                    />
                  </div>
                </div>
              </div>
              <h5 className="card-header">Student Data & Lables</h5>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Student Id Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("studIdLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Aadhar No Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("adharNoLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Full Name Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("fullNameLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Mother Name Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("motherNameLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Nationality Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("nationalityLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Mother Tongue Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("motherTongueLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Religion Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("religionLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Caste Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("casteLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Sub Caste Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("subCasteLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Place of Birth Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("placeOfBirthLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Student Taluka Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("studTalukaLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      Student District Label
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("studDistrictLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Student State Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("studStateLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Date of Birth Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("dobLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">DOB In Letter Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("dobInLetterLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Last School Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("lastSchoolLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Admission Date Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("admissionDateLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Standard Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("stdLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Progress Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("progressLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Conduct Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("conductLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      Date of Leaving Label
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("dateOfLeavingLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      Std. In Which Studying Label
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("stdInWhichStudyingLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Since When Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("sinceWhenLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">
                      Studying Since When In Words Label
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("studyingSinceWhenInWordsLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Leaving Reason Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("leavingReasonLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Remark Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("remarkLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Description Label</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("descriptionLabel")}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3">
                    <label for="validationCustom01">Letter Head</label>
                    <select
                      className="form-control"
                      {...register("isLetterHead")}
                    >
                      <option value="true">ON</option>
                      <option value="false">OFF</option>
                    </select>
                  </div>
                </div>
                <div className="form-row mt-5">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button className="btn btn-primary" type="submit">
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
