import React, { useState, useEffect } from "react";
import { getAllStudents } from "../../services/APIService";
import Loader from "../../services/Loader";

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalStudent, setTotalStudent] = useState(0);
  const [downloaded, setDownloaded] = useState(0);
  const [pending, setPending] = useState(0);

  useEffect(() => {
    loadStudents();
  }, []);

  const loadStudents = async () => {
    try {
      setIsLoading(true);
      var response = await getAllStudents();
      setTotalStudent(response.length);
      setDownloaded(response.filter((item) => item.isDownloaded).length);
      setPending(response.filter((item) => item.isDownloaded === false).length);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="dashboard-influence">
      {isLoading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h3 className="mb-2">Dashboard </h3>

              <div className="page-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb"></ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-inline-block">
                  <h5 className="text-muted">Total Students</h5>
                  <h2 className="mb-0"> {totalStudent}</h2>
                </div>
                <div className="float-right icon-circle-medium  icon-box-lg  bg-info-light mt-1">
                  <i className="fa fa-user fa-fw fa-sm text-info"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-inline-block">
                  <h5 className="text-muted">LC Downloaded</h5>
                  <h2 className="mb-0"> {downloaded}</h2>
                </div>
                <div className="float-right icon-circle-medium  icon-box-lg  bg-primary-light mt-1">
                  <i className="fa fa-user fa-fw fa-sm text-primary"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-inline-block">
                  <h5 className="text-muted">LC Pending to Download</h5>
                  <h2 className="mb-0">{pending}</h2>
                </div>
                <div className="float-right icon-circle-medium  icon-box-lg  bg-secondary-light mt-1">
                  <i className="fa fa-user fa-fw fa-sm text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
