// // actions.js
// export const increment = () => {
//   return {
//     type: "INCREMENT",
//   };
// };

// export const decrement = () => {
//   return {
//     type: "DECREMENT",
//   };
// };

export const loginData = (data) => {
  return {
    type: "AdminLogin",
    data: data,
  };
};

export const LCData = (data) => {
  return {
    type: "LCData",
    data: data,
  };
};

export const UpdateData = (data) => {
  return {
    type: "UpdateData",
    data: data,
  };
};

export const ExcelData = (data) => {
  return {
    type: "ExcelData",
    data: data,
  };
};

export const FontData = (data) => {
  return {
    type: "FontData",
    data: data,
  };
};
