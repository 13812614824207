import React, { useState, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getAllStudents } from "../../services/APIService";
import { useSelector, useDispatch } from "react-redux";
import { loginData } from "../../redux/actions";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    if (window.confirm("Confirm Logout..?")) {
      localStorage.clear();
      navigate("/");
      dispatch(loginData(null));
      // window.location.reload();
    }
  };

  return (
    <div className="dashboard-main-wrapper">
      <div className="dashboard-header">
        <nav className="navbar navbar-expand-lg bg-white fixed-top">
          <NavLink className="navbar-brand" to={"/dashboard"}>
            LC App - EduMaster
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto navbar-right-top">
              <li className="nav-item dropdown nav-user">
                <a
                  className="nav-link nav-user-img"
                  href="#"
                  id="navbarDropdownMenuLink2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="assets/images/avatar.png"
                    alt=""
                    className="user-avatar-md rounded-circle"
                  />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right nav-user-dropdown"
                  aria-labelledby="navbarDropdownMenuLink2"
                >
                  <div className="nav-user-info">
                    <h5 className="mb-0 text-white nav-user-name">
                      Welcome, Admin
                    </h5>
                    <span className="status"></span>
                    <span className="ml-2">Available</span>
                  </div>
                  {/* <a className="dropdown-item" href="#">
                    <i className="fas fa-user mr-2"></i>Account
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fas fa-cog mr-2"></i>Setting
                  </a> */}
                  <a
                    onClick={logout}
                    className="dropdown-item"
                    href="javascript:void(0);"
                  >
                    <i className="fas fa-power-off mr-2"></i>Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="nav-left-sidebar sidebar-dark">
        <div className="menu-list">
          <nav className="navbar navbar-expand-lg navbar-light">
            <a className="d-xl-none d-lg-none" href="#">
              Dashboard
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav flex-column">
                {/* <li className="nav-divider">Menu</li> */}
                <li className="nav-item ">
                  <NavLink className="nav-link" to={"/dashboard"}>
                    <i className="fa fa-fw fa-home"></i>Dashboard
                    {/* <span className="badge badge-success">6</span> */}
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to={"/dashboard/upload"}>
                    <i className="fa fa-fw fa-arrow-up"></i>Upload Student Data
                    {/* <span className="badge badge-success">6</span> */}
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to={"/dashboard/download"}>
                    <i className="fa fa-fw fa-table"></i>Download LC
                    {/* <span className="badge badge-success">6</span> */}
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink className="nav-link" to={"/dashboard/manage-label"}>
                    <i className="fa fa-fw fa-tag"></i>Manage Data & Labels
                    {/* <span className="badge badge-success">6</span> */}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={logout}
                    href="javascript:void(0);"
                  >
                    <i className="fa fa-fw fa-power-off"></i>Logout
                    {/* <span className="badge badge-success">6</span> */}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-2"
                    aria-controls="submenu-2"
                  >
                    <i className="fa fa-fw fa-rocket"></i>UI Elements
                  </a>
                  <div id="submenu-2" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="pages/cards.html">
                          Cards{" "}
                          <span className="badge badge-secondary">New</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/general.html">
                          General
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/carousel.html">
                          Carousel
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/listgroup.html">
                          List Group
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/typography.html">
                          Typography
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/accordions.html">
                          Accordions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/tabs.html">
                          Tabs
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-3"
                    aria-controls="submenu-3"
                  >
                    <i className="fas fa-fw fa-chart-pie"></i>Chart
                  </a>
                  <div id="submenu-3" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="pages/chart-c3.html">
                          C3 Charts
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/chart-chartist.html"
                        >
                          Chartist Charts
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/chart-charts.html">
                          Chart
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/chart-morris.html">
                          Morris
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/chart-sparkline.html"
                        >
                          Sparkline
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/chart-gauge.html">
                          Guage
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item ">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-4"
                    aria-controls="submenu-4"
                  >
                    <i className="fab fa-fw fa-wpforms"></i>Forms
                  </a>
                  <div id="submenu-4" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="pages/form-elements.html">
                          Form Elements
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/form-validation.html"
                        >
                          Parsely Validations
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/multiselect.html">
                          Multiselect
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/datepicker.html">
                          Date Picker
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/bootstrap-select.html"
                        >
                          Bootstrap Select
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-5"
                    aria-controls="submenu-5"
                  >
                    <i className="fas fa-fw fa-table"></i>Tables
                  </a>
                  <div id="submenu-5" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="pages/general-table.html">
                          General Tables
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/data-tables.html">
                          Data Tables
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-divider">Features</li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-6"
                    aria-controls="submenu-6"
                  >
                    <i className="fas fa-fw fa-file"></i> Pages{" "}
                  </a>
                  <div id="submenu-6" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="pages/blank-page.html">
                          Blank Page
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/blank-page-header.html"
                        >
                          Blank Page Header
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/login.html">
                          Login
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/404-page.html">
                          404 page
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/sign-up.html">
                          Sign up Page
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/forgot-password.html"
                        >
                          Forgot Password
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/pricing.html">
                          Pricing Tables
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/timeline.html">
                          Timeline
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/calendar.html">
                          Calendar
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/sortable-nestable-lists.html"
                        >
                          Sortable/Nestable List
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/widgets.html">
                          Widgets
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/media-object.html">
                          Media Objects
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/cropper-image.html">
                          Cropper
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/color-picker.html">
                          Color Picker
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-7"
                    aria-controls="submenu-7"
                  >
                    <i className="fas fa-fw fa-inbox"></i>Apps{" "}
                    <span className="badge badge-secondary">New</span>
                  </a>
                  <div id="submenu-7" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="pages/inbox.html">
                          Inbox
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/email-details.html">
                          Email Detail
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/email-compose.html">
                          Email Compose
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/message-chat.html">
                          Message Chat
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-8"
                    aria-controls="submenu-8"
                  >
                    <i className="fas fa-fw fa-columns"></i>Icons
                  </a>
                  <div id="submenu-8" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/icon-fontawesome.html"
                        >
                          FontAwesome Icons
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/icon-material.html">
                          Material Icons
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="pages/icon-simple-lineicon.html"
                        >
                          Simpleline Icon
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/icon-themify.html">
                          Themify Icon
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/icon-flag.html">
                          Flag Icons
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/icon-weather.html">
                          Weather Icon
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-9"
                    aria-controls="submenu-9"
                  >
                    <i className="fas fa-fw fa-map-marker-alt"></i>Maps
                  </a>
                  <div id="submenu-9" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="pages/map-google.html">
                          Google Maps
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="pages/map-vector.html">
                          Vector Maps
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="collapse"
                    aria-expanded="false"
                    data-target="#submenu-10"
                    aria-controls="submenu-10"
                  >
                    <i className="fas fa-f fa-folder"></i>Menu Level
                  </a>
                  <div id="submenu-10" className="collapse submenu">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Level 1
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          data-toggle="collapse"
                          aria-expanded="false"
                          data-target="#submenu-11"
                          aria-controls="submenu-11"
                        >
                          Level 2
                        </a>
                        <div id="submenu-11" className="collapse submenu">
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <a className="nav-link" href="#">
                                Level 1
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="#">
                                Level 2
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Level 3
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="dashboard-wrapper">
        <Outlet />

        <div className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                Copyright © 2024-2025 . All rights reserved by{" "}
                <a target="_blank" href="https://atjoin.in/">
                  ATJOIN PVT. LTD.
                </a>
                .
              </div>
              {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="text-md-right footer-links d-none d-sm-block">
                  <a href="javascript: void(0);">About</a>
                  <a href="javascript: void(0);">Support</a>
                  <a href="javascript: void(0);">Contact Us</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
