import React from "react";

export default function Loader() {
  return (
    <div class="spinner-overlay" id="spinnerOverlay">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="dashboard-spinner spinner-xs"></span>
        </div>
      </div>
    </div>
  );
}
