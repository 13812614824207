import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// comman comps
import Login from "../comps/comman/Login";

// admin comps
import Dashboard from "../comps/admin/Dashboard";
import Home from "../comps/admin/Home";
import UploadData from "../comps/admin/UploadData";
import LCPdf from "../comps/admin/LCPdf";
import ManageLabel from "../comps/admin/ManageLabel";
import EditRecord from "../comps/admin/EditRecord";
import DownloadLC from "../comps/admin/DownloadLC";

export default function Router() {
  const AdminLogin = useSelector((state) => state.AdminLogin);

  return (
    <BrowserRouter>
      <Routes>
        {/* visitor routes */}
        <Route path="" element={<Login />} />
        {/* admin routes */}
        <Route
          path="dashboard"
          element={AdminLogin !== null ? <Dashboard /> : <Navigate to="/" />}
        >
          <Route path="" element={<Home />} />
          <Route path="upload" element={<UploadData />} />
          <Route path="ViewLC" element={<LCPdf />} />
          <Route path="manage-label" element={<ManageLabel />} />
          <Route path="edit-student" element={<EditRecord />} />
          <Route path="download" element={<DownloadLC />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
