// store.js
import { createStore } from "redux";

// Initial state
const initialState = {
  // counter: 0,
  // AdminLogin: null,
  LCData: JSON.parse(localStorage.getItem("LCData")),
  UpdateData: JSON.parse(localStorage.getItem("UpdateData")),
  FontData: JSON.parse(localStorage.getItem("FontData")),
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "AdminLogin":
      return { AdminLogin: JSON.parse(localStorage.getItem("AdminLogin")) };
    case "LCData":
      return { LCData: JSON.parse(localStorage.getItem("LCData")) };
    case "UpdateData":
      return { UpdateData: JSON.parse(localStorage.getItem("UpdateData")) };
    case "ExcelData":
      return { ExcelData: action.data };
    case "FontData":
      return { FontData: JSON.parse(localStorage.getItem("FontData")) };
    default:
      return state;
  }
};

// Create store
const store = createStore(reducer);

export default store;
