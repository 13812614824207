import React, { useState, useEffect } from "react";
import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  PDFViewer,
  Svg,
  Line,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import Loader from "../../services/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllLabel,
  getFonts,
  setDefaultFont,
  uploadFont,
} from "../../services/APIService";

export default function LCPdf() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const LCData = useSelector((state) => state.LCData);
  const [label, setLabel] = useState();
  const [fontData, setFontData] = useState(location.state);

  Font.register({
    family: fontData?.fontFamily,
    fonts: [
      {
        src: `https://lcappapi.atopd.in/${fontData?.normal}`,
      },
      {
        src: `https://lcappapi.atopd.in/${fontData?.bold}`,
        fontWeight: "bold",
      },
    ],
  });

  useEffect(() => {
    loadLabels();
  }, []);

  const loadLabels = async () => {
    try {
      setIsLoading(true);
      var response = await getAllLabel();
      setLabel(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const back = () => {
    navigate("/dashboard/download");
  };

  const styles = StyleSheet.create({
    page: {
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.6,
      flexDirection: "column",
      fontFamily: fontData?.fontFamily,
      backgroundColor: "#E4E4E4",
    },

    pageWithoutHeaderFooter: {
      paddingTop: 180,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.6,
      flexDirection: "column",
      fontFamily: fontData?.fontFamily,
      backgroundColor: "#E4E4E4",
    },

    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      // alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },

    row: {
      // flex: 1,
      flexDirection: "row",
      // alignItems: "center",
      // justifyContent: "space-between",
      color: "#3E3E3E",
    },

    onlyCenter: {
      flex: 1,
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },

    titleContainer: { flexDirection: "row", marginTop: 5 },

    logo: { width: 90 },

    reportTitle: { fontSize: 12, textAlign: "center" },
    bigTitle: {
      fontSize: 18,
      textAlign: "center",
      fontWeight: "bold",
    },

    footerDesignations: {
      fontSize: 11,
      textAlign: "center",
      fontWeight: "bold",
    },

    addressTitle: {
      fontSize: 11,
      fontWeight: "bold",
    },

    invoice: { fontWeight: "bold", fontSize: 20 },

    invoiceNumber: { fontSize: 11, fontWeight: "bold" },

    heading: { fontWeight: "bold", fontSize: 11 },
    address: { fontSize: 11 },
  });

  const DocHeader = () => (
    <View style={styles.titleContainer}>
      <View style={styles.onlyCenter}>
        {/* <Image style={styles.logo} src={logo} /> */}
        <Text style={styles.reportTitle}>{label?.headerTitle1}</Text>
        <Text style={styles.reportTitle}>{label?.headerTitle2}</Text>

        <Text style={styles.bigTitle}>{label?.headerTitle3}</Text>
        <Text style={styles.bigTitle}>{label?.headerTitle4}</Text>
      </View>
    </View>
  );

  const DocTitle = () => (
    <View style={styles.titleContainer}>
      <View style={styles.onlyCenter}>
        {!LCData.isDownloaded ? (
          <Text style={styles.bigTitle}>{label?.docOriginalTitle}</Text>
        ) : (
          <Text style={styles.bigTitle}>{label?.docDuplicateTitle}</Text>
        )}
      </View>
    </View>
  );

  const GeneralInfo1 = () => (
    <View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.regNoLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{label?.regNo}</Text>
          </View>
          <View style={styles.row}>
            {/* <Text style={{ fontWeight: "bold", fontSize: 11 }}>R.No.: </Text> */}
            <Text style={{ fontSize: 11 }}>{label?.address}</Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            {/* <Text style={{ fontWeight: "bold", fontSize: 11 }}>R.No.: </Text> */}
            <Text style={{ fontSize: 11 }}>{label?.tag}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.talukaLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{label?.taluka}, </Text>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.districtLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{label?.district}</Text>
          </View>
        </View>
      </View>
    </View>
  );

  const GeneralInfo2 = () => (
    <View style={{ flexDirection: "row" }}>
      <View style={styles.spaceBetween}>
        <View style={styles.row}>
          <Text style={{ fontWeight: "bold", fontSize: 11 }}>
            {label?.phoneLabel}{" "}
          </Text>
          <Text style={{ fontSize: 11 }}>{label?.phone}</Text>
        </View>
        <View style={styles.row}>
          <Text style={{ fontWeight: "bold", fontSize: 11 }}>
            {label?.emailLabel}{" "}
          </Text>
          <Text style={{ fontSize: 11 }}>{label?.email}</Text>
        </View>
        <View style={styles.row}>
          <Text style={{ fontWeight: "bold", fontSize: 11 }}>
            {label?.genRegNoLabel}{" "}
          </Text>
          <Text style={{ fontSize: 11 }}>
            {label?.genRegNo} / {LCData.regNo}
          </Text>
        </View>
      </View>
    </View>
  );

  const GeneralInfo3 = () => (
    <View style={{ flexDirection: "row", marginTop: 5 }}>
      <View style={styles.spaceBetween}>
        <View style={styles.row}>
          <Text style={{ fontWeight: "bold", fontSize: 11 }}>
            {label?.uDiseNoLabel}{" "}
          </Text>
          <Text style={{ fontSize: 11 }}>{label?.uDiseNo}</Text>
        </View>
        <View style={styles.row}>
          <Text style={{ fontWeight: "bold", fontSize: 11 }}>
            {label?.boardLabel}{" "}
          </Text>
          <Text style={{ fontSize: 11 }}>{label?.board}</Text>
        </View>
        <View style={styles.row}>
          <Text style={{ fontWeight: "bold", fontSize: 11 }}>
            {label?.schoolIndexLabel}{" "}
          </Text>
          <Text style={{ fontSize: 11 }}>{label?.schoolIndex}</Text>
        </View>
      </View>
    </View>
  );

  const HRLine = () => (
    <Svg height="5" style={{ width: "100%" }}>
      <Line x1="0" y1="5" x2="600" y2="5" strokeWidth={2} stroke="rgb(0,0,0)" />
    </Svg>
  );

  const StudentInfo = () => (
    <View>
      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.studIdLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.studId}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.adharNoLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.adharNo}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.fullNameLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>
              {LCData.firstName} {LCData.middleName} {LCData.lastName}
            </Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.motherNameLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.motherName}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            color: "#3E3E3E",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              color: "#3E3E3E",
              marginRight: 50,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.nationalityLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.nationality}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.motherTongueLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.motherTongue}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            color: "#3E3E3E",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              color: "#3E3E3E",
              marginRight: 50,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.religionLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.religion}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.casteLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.caste}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.subCasteLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.subCaste}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.placeOfBirthLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.placeOfBirth}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.studTalukaLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.taluka}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          marginTop: 5,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            color: "#3E3E3E",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              color: "#3E3E3E",
              marginRight: 181,
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.studDistrictLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.district}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.studStateLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.state}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.dobLabel}{" "}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          marginTop: 5,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-evenly",
            color: "#3E3E3E",
          }}
        >
          <View style={styles.row}>
            <Text style={{ fontSize: 11 }}>{LCData.dob}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontSize: 11 }}>{LCData.dobInLetter}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.lastSchoolLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.lastSchool}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.admissionDateLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.admissionDate}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.stdLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.std}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.progressLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.progress}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.conductLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.conduct}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.dateOfLeavingLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.dateOfLeaving}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.stdInWhichStudyingLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>
              {LCData.stdInWhichStudying} {label?.sinceWhenLabel}{" "}
              {LCData.sinceWhen}
            </Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11, marginLeft: 60 }}>
              {label?.studyingSinceWhenInWordsLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>
              {LCData.studyingSinceWhenInWords}
            </Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.leavingReasonLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.leavingReason}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.remarkLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}>{LCData.remark}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontSize: 11, marginLeft: 65 }}>
              {LCData.description}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );

  const FooterInfo = () => (
    <View>
      <View style={styles.titleContainer}>
        <View style={styles.spaceBetween}>
          <Text style={styles.address}>{label?.footerMsg} </Text>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={styles.spaceBetween}>
          <View style={styles.row}>
            <Text style={{ fontWeight: "bold", fontSize: 11 }}>
              {label?.dateLabel}{" "}
            </Text>
            <Text style={{ fontSize: 11 }}> {label?.date}</Text>
          </View>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 30 }}>
        <View style={styles.spaceBetween}>
          <Text style={styles.footerDesignations}>{label?.designation1}</Text>
          <Text style={styles.footerDesignations}>{label?.designation2}</Text>
          <Text style={styles.footerDesignations}>{label?.designation3}</Text>
        </View>
      </View>

      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <View style={styles.row}>
          <Text style={{ fontWeight: "bold", fontSize: 9 }}>
            {label?.noteLabel}{" "}
          </Text>
          <Text style={{ fontSize: 9 }}>{label?.note}</Text>
        </View>
      </View>
    </View>
  );

  const MainDocument = () => (
    <Document>
      <Page
        size="A4"
        style={
          label?.isLetterHead ? styles.pageWithoutHeaderFooter : styles.page
        }
      >
        {label?.isLetterHead ? <></> : <DocHeader />}
        {label?.isLetterHead ? <></> : <GeneralInfo1 />}
        {label?.isLetterHead ? <></> : <GeneralInfo2 />}
        {label?.isLetterHead ? <></> : <HRLine />}
        {label?.isLetterHead ? <></> : <GeneralInfo3 />}
        {label?.isLetterHead ? <></> : <HRLine />}
        <DocTitle />
        <StudentInfo />
        {label?.isLetterHead ? <></> : <HRLine />}
        {label?.isLetterHead ? <></> : <FooterInfo />}
        {/* <DocHeader />
        <GeneralInfo1 />
        <GeneralInfo2 />
        <HRLine />
        <GeneralInfo3 />
        <HRLine />
        <DocTitle />
        <StudentInfo />
        <HRLine />
        <FooterInfo /> */}
      </Page>
    </Document>
  );
  return (
    <div className="dashboard-influence">
      {isLoading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-9 col-9">
            <div className="page-header">
              {/* <h3 className="mb-2">Download LC</h3> */}

              <PDFDownloadLink document={<MainDocument />} fileName="LC.pdf">
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Loader />
                  ) : (
                    <button className="btn btn-rounded btn-primary ">
                      Download LC
                    </button>
                  )
                }
              </PDFDownloadLink>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3">
            <div className="page-header">
              <button onClick={back} className="btn btn-rounded btn-danger">
                Back
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <PDFViewer height="650" className="app">
                <MainDocument />
              </PDFViewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
